<template>
  <v-layout>
    <v-flex px-4>
      <blockquote class="pre-line width-blockquote">
        {{ adminMessage.message }}
      </blockquote>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("messageAdmin", ["adminMessage"])
  },
  methods: {
    ...mapActions("messageAdmin", ["getMessageAdmin"])
  },
  created() {
    this.getMessageAdmin();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.pre-line {
  white-space: pre-line;
}
.width-blockquote {
  width: calc(100vw - 96px);
}
</style>
